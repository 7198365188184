import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, AVALANCHE_FUJI, ETHEREUM, SHIBARIUM, GOERLI, PUPPY_NET, FANTOM, MUMBAI } from "config/chains";
import arbitrum from "img/ic_arbitrum_24.svg";
import avalancheTestnet from "img/ic_avalanche_testnet_24.svg";

import ethereum from "img/ic_eth_24.svg";
import shibarium from "img/ic_shib_24.svg";

import ethereum_sm from "img/ic_eth_16.svg";
import shibarium_sm from "img/ic_shib_16.svg";


import goerli from "img/ic_goerli_24.svg";
import puppy from "img/ic_puppy_24.svg";

import fantom from "img/ic_ftm_24.svg";
import matic from "img/ic_matic_24.svg";

import fantom_sm from "img/ic_ftm_16.svg";
import mumbai_sm from "img/ic_matic_16.svg";

import gmxIcon from "img/ic_gmx_40.svg";
import glpIcon from "img/ic_glp_40.svg";
import tailIcon from "img/ic_tail_40.svg";
import tlpIcon from "img/ic_tlp_40.svg";

import gmxArbitrum from "img/ic_gmx_arbitrum.svg";
import gmxAvax from "img/ic_gmx_avax.svg";
import glpArbitrum from "img/ic_glp_arbitrum.svg";
import glpAvax from "img/ic_glp_avax.svg";

const ICONS = {
  // [ARBITRUM]: {
  //   network: arbitrum,
  //   gmx: gmxArbitrum,
  //   glp: glpArbitrum,
  //   tail: tailIcon,
  //   tlp: tlpIcon,
  // },
  // [AVALANCHE]: {
  //   network: avalanche,
  //   gmx: gmxAvax,
  //   glp: glpAvax,
  //   tail: tailIcon,
  //   tlp: tlpIcon,
  // },
  [ARBITRUM]: {
    network: ethereum,
    icon: ethereum_sm,
    gmx: gmxArbitrum,
    glp: glpArbitrum,
    tail: tailIcon,
    tlp: tlpIcon,
  },
  [AVALANCHE]: {
    network: shibarium,
    icon: shibarium_sm,
    gmx: gmxAvax,
    glp: glpAvax,
    tail: tailIcon,
    tlp: tlpIcon,
  },
  [ARBITRUM_TESTNET]: {
    network: arbitrum,
  },
  [AVALANCHE_FUJI]: {
    network: avalancheTestnet,
  },
  [ETHEREUM]: {
    network: ethereum,
    icon: ethereum_sm,
    gmx: gmxAvax,
    glp: glpAvax,
    tail: tailIcon,
    tlp: tlpIcon,
  },
  [SHIBARIUM]: {
    network: shibarium,
    icon: shibarium_sm,
    gmx: gmxAvax,
    glp: glpAvax,
    tail: tailIcon,
    tlp: tlpIcon,
  },
  [GOERLI]: {
    network: goerli,
    icon: ethereum_sm,
    gmx: gmxAvax,
    glp: glpAvax,
    tail: tailIcon,
    tlp: tlpIcon,
  },
  [PUPPY_NET]: {
    network: puppy,
    icon: shibarium_sm,
    gmx: gmxAvax,
    glp: glpAvax,
    tail: tailIcon,
    tlp: tlpIcon,
  },
  [FANTOM]: {
    network: fantom,
    icon: fantom_sm,
    gmx: gmxAvax,
    glp: glpAvax,
    tail: tailIcon,
    tlp: tlpIcon,
  },
  [MUMBAI]: {
    network: goerli,
    icon: mumbai_sm,
    gmx: gmxAvax,
    glp: glpAvax,
    tail: tailIcon,
    tlp: tlpIcon,
  },
  common: {
    gmx: gmxIcon,
    glp: glpIcon,
    tail: tailIcon,
    tlp: tlpIcon,
  },
};

export function getIcon(chainId: number | "common", label: string) {
  if (chainId in ICONS) {
    if (label in ICONS[chainId]) {
      return ICONS[chainId][label];
    }
  }
}
export function getIcons(chainId: number | "common") {
  if (!chainId) return;
  if (chainId in ICONS) {
    return ICONS[chainId];
  }
}
